import { useProfile } from "@/hooks/useProfile";
import { Button, DialogTitle, Field, Input, Label, Select } from "@headlessui/react";
import { Link, useLoaderData } from "@tanstack/react-router";
import { t } from "i18next";
import { useEffect, useState } from "react";
import BasicSlideOver from "../../components/BasicSlideOver";
import { Spinner } from "../../components/Spinner";
import { checkIsAdmin, dealerRoles } from "../../enums/enums";
import { useArchiveUser, useUpdateUserValues } from "../../hooks/useDealer";
import { UserResource } from "../../types/auth";
import { ReactFormSubmit } from "../../types/helperTypes";
import { errorMessage, successMessage } from "../../utils/toast";

type Props = {
    user: UserResource['data'] | null;
    onClose: () => void;
}
export default function EditDealerUser({ user, onClose }: Props) {
    const { data: profile } = useProfile();
    const [open, setOpen] = useState(false);
    const { user: loggedInUser } = useLoaderData({ from: "/_protected" })
    const { mutate, isPending } = useUpdateUserValues()
    const archive = useArchiveUser()
    const isAdmin = checkIsAdmin(loggedInUser.role.value || "")
    function handleSetOpen(open: boolean) {
        if (open) {
            setOpen(true)
        } else {
            onClose()
        }
    }


    // When user is set, open slide-over, otherwise keep closed
    useEffect(() => {
        if (user) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [user]);


    async function onSubmit(e: ReactFormSubmit) {
        e.preventDefault()
        const formData = new FormData(e.currentTarget)
        if (!user) {
            errorMessage(t("status:error.unknownError"));
            onClose();
            return;
        }

        mutate(
            {
                id: user.id,
                role: { id: formData.get("role")?.toString() || user.role.value },
                initials: formData.get("initials")?.toString() || user.initials,
                name: formData.get("name")?.toString() || user.initials,
                phone: formData.get("phone")?.toString() || user.initials,
            },
            {
                onSuccess: () => {
                    successMessage(t("status:success.userUpdated"))
                    onClose()
                },
                onError(error) {
                    errorMessage(error);
                },
            }
        )
    }

    function onBlockUser() {
        if (!user) {
            errorMessage(t("status:error.unknownError"));
            onClose();
            return;
        }
        if (!window.confirm(t("status:confirm.blockUser", { user: user.name }))) return;
        if (window.prompt("Skriv 'BLOKER BRUGER' for at blokere denne bruger") !== "BLOKER BRUGER") {
            successMessage("Brugeren blev IKKE blokeret. Du skrev forkert.")
            return;
        }
        if (!window.confirm(t("status:confirm.deleteUserReallySure"))) return;
        archive.mutate(user.id, {
            onSuccess: () => {
                successMessage(t("status:success.userBlocked"))
                onClose()
            },
            onError(error) {
                errorMessage(error);
            },
        })
    }

    return (
        <BasicSlideOver size="md" setOpen={handleSetOpen} open={open}>
            <Spinner className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl' loading={isPending || archive.isPending}>
                <div className="px-4 sm:px-6">
                    <DialogTitle as='h3' className="text-2xl font-semibold text-gray-800 mb-5">{t("edit.fix")} {t("user")}</DialogTitle>
                    <p>{t("content:dealer.Here you can correct a user's information. Note that the user's email address is unique. If the user has changed their email address, a new user must be invited.")}</p>
                </div>
                <form onSubmit={onSubmit}
                    className="relative mt-6 flex-1 px-4 sm:px-6 flex flex-col gap-4"
                >
                    <Field disabled={!isAdmin}>
                        <Label className="input-label">{t("fullName")}</Label>
                        <Input
                            type='text'
                            autoComplete='name'
                            name="name"
                            className="input mt-1"
                            defaultValue={user?.name}
                        />
                    </Field>
                    <Field disabled={!isAdmin}>
                        <Label className="input-label">{t("initials")}</Label>
                        <Input
                            type='text'
                            name="initials"
                            className="input mt-1"
                            defaultValue={user?.initials}
                        />
                    </Field>
                    <Field disabled={!isAdmin}>
                        <Label className="input-label">{t("phoneNumber")}</Label>
                        <Input
                            type='tel'
                            name="phone"
                            autoComplete="work tel"
                            className="input mt-1"
                            defaultValue={user?.phone}
                        />
                    </Field>
                    <Field disabled>
                        <Label className="input-label">{t("usersEmailAddress")}</Label>
                        <Input
                            type='email'
                            name="email"
                            autoComplete="work email"
                            className="input mt-1"
                            defaultValue={user?.email}
                        />
                    </Field>

                    <Field>
                        <Label className="input-label">{t("role")}/{t("permissions")}</Label>
                        <Select
                            required
                            name="role"
                            className={"input mt-1"}
                            defaultValue={user?.role.value}
                        >
                            {dealerRoles.map(role => (
                                <option key={role.value} value={role.value}>{role.name}</option>
                            ))}
                        </Select>
                    </Field>

                    <div className="mt-auto pb-2 text-right space-x-4">
                        <Button
                            disabled={profile?.data.id === user?.id}
                            title=""
                            onClick={onBlockUser}
                            className="btn btn-outline danger"
                        >
                            {t("blockUser")}
                        </Button>
                        {profile?.data.id === user?.id && (
                            <Link to="/dealership/profile" className="link text-sm">{t("editOwnProfile")}</Link>
                        )}
                    </div>
                    <div className='pt-6 border-t text-right'>
                        <Button type='submit'
                            className="btn btn-primary"
                        >
                            {t("saveChanges")}
                        </Button>
                    </div>
                </form>
            </Spinner>
        </BasicSlideOver>

    )
}
