import { TaxCaseResource } from "@/types/taxTypes";
import { formatPrice } from "@/utils/string";
import { t } from "i18next";
import { useId } from "react";

type PriceQuoteProps = {
    assessment: TaxCaseResource['assessment'];
}
export default function PriceQuote({ assessment }: PriceQuoteProps) {
    const titleId = useId()
    const VAT = assessment?.total && assessment?.totalNoVat
        ? Math.ceil(assessment.total - assessment.totalNoVat)
        : null
    return (
        <div className="bg-gray-50 rounded-lg p-6">
            <h3 id={titleId} className="font-semibold text-base text-dark-blue mb-4">{t("forPayment")}</h3>
            <dl className="text-sm" aria-labelledby={titleId}>
                <div className="flex justify-between items-center mb-3">
                    <dt className="font-medium">{t("content:tax.priceQuote.Estimated registration fee")}</dt>
                    <dd className="text-dark-blue font-semibold">{formatPrice(assessment?.estimatedRegistrationFee, true)}</dd>
                </div>
                <div className="flex justify-between items-center mb-3">
                    <dt className="font-medium">{t("content:tax.priceQuote.Estimated collateral")}</dt>
                    <dd className="text-dark-blue font-semibold">{formatPrice(assessment?.estimatedCollateral, true)}</dd>
                </div>
                <div className="flex justify-between items-center mb-3">
                    <dt className="font-medium">{t("expeditionFee")}</dt>
                    <dd className="text-dark-blue font-semibold">{formatPrice(assessment?.fee, true)}</dd>
                </div>
                <div className="flex justify-between items-center mb-6">
                    <dt className="font-medium">{t("numberPlates")}</dt>
                    <dd className="text-dark-blue font-semibold">{formatPrice(assessment?.numberPlates, true)}</dd>
                </div>
                <div className="flex justify-between items-center text-base pt-6 mb-1 border-t">
                    <dt className="text-dark-blue">
                        <strong>{t("totalPrice")}</strong>
                    </dt>
                    <dd className="text-dark-blue">
                        <strong>{formatPrice(assessment?.total, true)}</strong>
                    </dd>
                </div>
                <div className="flex justify-between items-center">
                    <dt className="font-medium">{t("includingVat")}</dt>
                    <dd className="text-dark-blue font-semibold">{formatPrice(VAT, true)}</dd>
                </div>
            </dl>
        </div>
    )
}
